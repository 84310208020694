import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchContactMessages, updateMessage } from "../../services/";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Chip, Divider } from "@mui/material";
import Select from "react-select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Messages = () => {
  const [modal, setModal] = useState(false),
  [activeMessage, setActiveMessage] = useState(null)

    const { data, isLoading, refetch } = useQuery("messages", fetchContactMessages, {
      select: (data) => data.messages,
      refetchOnWindowFocus: false,
    });

    const updateStatus = async (status, id) => {
        try {
            await updateMessage({ is_read: status ? 0 : 1 }, id)
            alert("Messages have been successfully updated")
            window.location.reload()
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

  const columns = [
    {
        field: "membership",
        headerName: "Membership",
        flex: 0.5,
        renderCell: (params) => {
          return <Chip color={params.row.user_id ? `primary` : `default`} size="small" label={params.row.user_id ? `MEMBER` : `GUEST`} />
        }
    },
    {
      field: "fullname",
      headerName: "Name",
      flex: 0.5,
      renderCell: (params) => {
        return <span>{params.row.message_fullname ? params.row.message_fullname : params.row.user_fullname}</span>
      }
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
      renderCell: (params) => {
        return <span>{params.row.message_email ? params.row.message_email : params.row.user_email}</span>
      }
    },
    {
      field: "formatted_date_add",
      headerName: "Sent On",
      flex: 0.6,
    },
    {
      field: "formatted_modified",
      headerName: "Last Updated",
      flex: 0.6,
    },
    {
      field: "Actions",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <button
              className="btn btn-sm bg-gradient-info mr-2"
              data-toggle="tooltip"
              data-placement="top"
              title="Details"
              onClick={() => {
                setModal(true);
                setActiveMessage(params.row);
              }}
            >
              <i className="fa fa-eye" />
            </button>

            <button
              className={`btn btn-sm bg-gradient-${params.row.is_read ? `danger` : `success`}`}
              data-toggle="tooltip"
              data-placement="top"
              title={`Mark as ${params.row.is_read ? `unread` : `read`}`}
              onClick={() => {
                updateStatus(params.row.is_read, params.row.id);
              }}
            >
              <i className={`fa fa-envelope${params.row.is_read ? `` : `-open`}`} />
            </button>
          </div>
        );
      },
    },
  ];

  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <BootstrapDialog
        onClose={() => {
          setModal(false);
        }}
        fullScreen={true}
        aria-labelledby="customized-dialog-title"
        open={modal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Contact message details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="card card-warning">
          {/* <div className="card-header">
            <h3 className="card-title">
              Change {activeUser?.firstname} {data.lastname}'s Password
            </h3>
          </div> */}

          <div className="card-body">
          <h5>Message</h5>
          <p className="text-muted">{activeMessage?.message}</p>

          </div>
        </div>
      </BootstrapDialog>

      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Experience Messages</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                {/* <li className="breadcrumb-item active">Dashboard</li> */}
              </ol>
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
          {/* <div className="d-flex flex-row align-items-center mb-3">
            <a href="/new-event" className="btn bg-gradient-primary mr-3">
              <i className="fa fa-plus mr-1" /> New event
            </a>
          </div> */}

          <Box sx={{ height: "85vh", width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              getRowClassName={(params) => {
                return Number(params.row.is_read) ? `` : `unread-message-row`;
              }}
              pageSizeOptions={[25]}
              disableRowSelectionOnClick
            />
          </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Messages;
