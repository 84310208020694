import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateTier, fetchTiers } from "../../services/";
import { useParams } from "react-router-dom";

const EditTier = () => {
    const { id } = useParams()
  const saveChanges = async (values) => {
    try {
      await updateTier(values, id);
      alert("Tier has been successfully updated");
      window.location.href = "/tiers";
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  const { data, isLoading } = useQuery("info", () => fetchTiers(id), {
    select: (data) => data.tiers[0],
    refetchOnWindowFocus: false
  })

  if (isLoading) return <>Loading...</> 

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Tier</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/tiers">Tiers</a>
                  </li>
                  <li className="breadcrumb-item active">Edit</li>
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="row">
              <div className="col-lg-8">
                <div className="card card-warning">
                  <div className="card-header">
                    <h3 className="card-title">Tier info</h3>
                  </div>

                  <Formik
                    initialValues={{
                      name: data.name,
                      n_plays: data.n_plays,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveChanges(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required(),
                      n_plays: Yup.number().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Tier Name</label>

                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Tier Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    className="form-control"
                                  />
                                  {errors.name && touched.name && (
                                    <span className="text-danger text-sm">
                                      {errors.name}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>No. of Plays to reach</label>

                                  <input
                                    type="number"
                                    name="n_plays"
                                    placeholder="No. of Plays"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.n_plays}
                                    className="form-control"
                                  />
                                  {errors.n_plays && touched.n_plays && (
                                    <span className="text-danger text-sm">
                                      {errors.n_plays}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn bg-gradient-warning"
                              disabled={isSubmitting}
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditTier;
