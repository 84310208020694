import React from "react"
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
    Navigate
} from "react-router-dom"
import { getToken } from "./utils"

import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import Admins from "./pages/Admins"
import EditAdmin from "./pages/Admins/EditAdmin"
import AddAdmin from "./pages/Admins/AddAdmin"
import Players from "./pages/Players"
import AddPlayer from "./pages/Players/AddPlayer"
import Events from "./pages/Events"
import AddEvent from "./pages/Events/AddEvent"
import EditEvent from "./pages/Events/EditEvent"
import Vouchers from "./pages/Vouchers"
import AddVoucher from "./pages/Vouchers/AddVoucher"
import EditVoucher from "./pages/Vouchers/EditVoucher"
import Newsletter from "./pages/Newsletter"
import Tiers from "./pages/Tiers"
import AddTier from "./pages/Tiers/AddTier"
import EditTier from "./pages/Tiers/EditTier"
import Gallery from "./pages/Gallery"
import Experience from "./pages/Experience"
import Messages from "./pages/Messages"
import Questions from "./pages/Events/Questions"

const ProtectedRoute = ({ children }) => {
    const isAuth = getToken()

    if (!isAuth) return <Navigate to="/login" />
    return children
}

const UnprotectedRoute = ({ children }) => {
    const isAuth = getToken()
    
    if (isAuth) return <Navigate to="/" />
    return children
}

export const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact element={<ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>} />
                <Route path="/admins" exact element={<ProtectedRoute>
                    <Admins />
                </ProtectedRoute>} />
                <Route path="/edit-admin/:id" exact element={<ProtectedRoute>
                    <EditAdmin />
                </ProtectedRoute>} />
                <Route path="/new-admin" exact element={<ProtectedRoute>
                    <AddAdmin />
                </ProtectedRoute>} />
                <Route path="/players" exact element={<ProtectedRoute>
                    <Players />
                </ProtectedRoute>} />
                <Route path="/new-player" exact element={<ProtectedRoute>
                    <AddPlayer />
                </ProtectedRoute>} />
                <Route path="/events" exact element={<ProtectedRoute>
                    <Events />
                </ProtectedRoute>} />
                <Route path="/new-event" exact element={<ProtectedRoute>
                    <AddEvent />
                </ProtectedRoute>} />
                <Route path="/edit-event/:id" exact element={<ProtectedRoute>
                    <EditEvent />
                </ProtectedRoute>} />
                <Route path="/vouchers" exact element={<ProtectedRoute>
                    <Vouchers />
                </ProtectedRoute>} />
                <Route path="/new-voucher" exact element={<ProtectedRoute>
                    <AddVoucher />
                </ProtectedRoute>} />
                <Route path="/edit-voucher/:id" exact element={<ProtectedRoute>
                    <EditVoucher />
                </ProtectedRoute>} />
                <Route path="/newsletter" exact element={<ProtectedRoute>
                    <Newsletter />
                </ProtectedRoute>} />
                <Route path="/tiers" exact element={<ProtectedRoute>
                    <Tiers />
                </ProtectedRoute>} />
                <Route path="/new-tier" exact element={<ProtectedRoute>
                    <AddTier />
                </ProtectedRoute>} />
                <Route path="/edit-tier/:id" exact element={<ProtectedRoute>
                    <EditTier />
                </ProtectedRoute>} />
                <Route path="/gallery" exact element={<ProtectedRoute>
                    <Gallery />
                </ProtectedRoute>} />
                <Route path="/experience" exact element={<ProtectedRoute>
                    <Experience />
                </ProtectedRoute>} />
                <Route path="/messages" exact element={<ProtectedRoute>
                    <Messages />
                </ProtectedRoute>} />
                <Route path="/event-questions/:event_id" exact element={<ProtectedRoute>
                    <Questions />
                </ProtectedRoute>} />
                <Route path="/login" element={<UnprotectedRoute>
                    <Login />
                </UnprotectedRoute>} />
                {/* <Route component={NotFound} /> */}
            </Switch>
        </Router>
    )
}