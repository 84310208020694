import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchGallery, uploadFiles } from "../../services/";
import { Chip } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

const Gallery = () => {
  const [files, setFiles] = useState([]),
    [status, setStatus] = useState(false);
  const uploadRef = useRef(null);

    const { data, isLoading, refetch } = useQuery("gallery", fetchGallery, {
      select: (data) => data.gallery,
      refetchOnWindowFocus: false,
    });

  const proceed = async (data) => {
    try {
      await uploadFiles(data);
      console.log("Submitted");
      // window.location.reload()
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
      console.log(e);
    }
  };

  if (isLoading) return <>Loading...</>

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Gallery</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="d-flex flex-row align-items-center mb-3">
            <Formik
              initialValues={{
                gallery: null,
              }}
              onSubmit={(values) => {
                const fd = new FormData();

                for (let index = 0; index < files.length; index++) {
                  fd.append(`gallery`, files[index], files[index].name);
                }

                // fd.append("gallery", files)
                proceed(fd);
              }}
              validationSchema={Yup.object().shape({
                gallery: Yup.mixed().required(
                  "Please select at least one image to upload"
                ),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  setFieldValue,
                  setFieldError,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                      <input
                        type="file"
                        multiple
                        name="gallery"
                        onChange={(e) => {
                          setFieldValue('gallery', e.target.files)
                          setFiles(e.target.files)
                        }}
                      />
                      {errors.gallery && touched.gallery && (
                        <span className="v-error">{errors.gallery}</span>
                      )}

                      {/* <button
                      className="btn bg-gradient-dark mr-2"
                      // onClick={() => {
                      //   uploadRef.current.click()
                      // }}
                    >Select file(s)</button> */}
                    </div>

                    <div className="form-group">
                      <button type="submit" className="btn bg-gradient-success">
                        Upload
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          
          <div className="row">
            {
              data.map((file) => (
                <div className="col-lg-2">
                  <img src={`${file}`} height="100" alt={file} />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
