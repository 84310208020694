import Cookies from "js-cookie"

const getToken = () => {
    return Cookies.get("sopadmintoken")
}

const setToken = (name, opts) => {
    Cookies.set(name, `${opts.value}`)
}

const signout = () => {
    Cookies.remove("sopadmintoken")
    window.location.href = process.env.REACT_APP_URL
}

export { getToken, setToken, signout }