import "./App.css";
import { Routes } from "./routes"
import {
  QueryClient,
  QueryClientProvider
} from "react-query"

function App() {
  const queryClient = new QueryClient()

  return (
    // <div className="wrapper">
    //   <div class="content-wrapper">
    <QueryClientProvider client={queryClient}>
       <Routes />
    </QueryClientProvider>
      // </div>
    // </div>
  );
}

export default App;
