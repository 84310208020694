import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { addPlayer, fetchPlayers, updatePlayer, sendEmail } from "../../services/";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { Chip } from "@mui/material";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const Players = () => {
  const [eventsOpen, setEventsOpen] = useState(false),
  [selectedRows, setSelectedRows] = useState([])

  const updateStatus = async (playerId, active) => {
    try {
      await updatePlayer({ active: !active }, playerId);
      alert("Player has been successfully updated");
      window.location.reload();
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      renderCell: (params) => {
        return <span>{`${params.row.firstname} ${params.row.lastname}`}</span>;
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 0.4,
      renderCell: (params) => {
        return <span>EGP {Number(params.row.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <Chip
            color={`${params.row.active ? `success` : `error`}`}
            label={`${params.row.active ? `Active` : `Inactive`}`}
            size="small"
          />
        );
      },
    },
    {
      field: "age",
      headerName: "Age",
      flex: 0.2,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 0.3,
    },
    {
      field: "area_name",
      headerName: "Area",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "E-mail address",
      flex: 0.7,
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      flex: 0.5,
    },
    {
      field: "dream_job",
      headerName: "Dream Job",
      flex: 0.7,
    },
    {
      field: "Actions",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <button
              className={`btn btn-sm bg-gradient-warning mr-2`}
              data-toggle="tooltip"
              data-placement="top"
              title={`Send Email`}
              onClick={() => {
                sendEmail({ users: [params.row.id] })
              }}
            >
              <i className={`fa fa-envelope`} />
            </button>

            <button
              className={`btn btn-sm bg-gradient-info mr-2`}
              data-toggle="tooltip"
              data-placement="top"
              title={`Events`}
              onClick={() => {
                setActiveUser(params.row)
                setEventsOpen(true)
              }}
            >
              <i className={`fa fa-calendar`} />
            </button>

            {/* <a
              className="btn btn-sm bg-gradient-dark mr-2"
              href={`/edit-player/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a> */}

            <button
              className={`btn btn-sm bg-gradient-${
                params.row.active ? `danger` : `success`
              }`}
              data-toggle="tooltip"
              data-placement="top"
              title={`${params.row.active ? `Deactivate` : `Activate`}`}
              onClick={() => updateStatus(params.row.id, params.row.active)}
            >
              <i className={`fa fa-${params.row.active ? `ban` : `check`}`} />
            </button>
          </div>
        );
      },
    },
  ];

  const fields = [
    {
      label: "firstname",
      key: "firstname",
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "`firstname` is required",
          level: "error",
        },
      ],
    },
    {
      label: "lastname",
      key: "lastname",
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "`lastname` is required",
          level: "error",
        },
      ],
    },
    {
      label: "area",
      key: "area",
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "`area` is required",
          level: "error",
        },
      ],
    },
    {
      label: "gender",
      key: "gender",
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "`gender` is required",
          level: "error",
        },
      ],
    },
    {
      label: "age",
      key: "age",
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "`age` is required",
          level: "error",
        },
      ],
    },
    {
      label: "dream_job",
      key: "dream_job",
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "`dream_job` is required",
          level: "error",
        },
      ],
    },
    {
      label: "email",
      key: "email",
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "`email` is required",
          level: "error",
        },
      ],
    },
    {
      label: "mobile",
      key: "mobile",
      fieldType: {
        type: "input",
      },
      // validations: [
      //   {
      //     rule: "required",
      //     errorMessage: "`mobile` is required",
      //     level: "error",
      //   },
      // ],
    },
  ];

  useEffect(() => {

  }, [])

  const [activeUser, setActiveUser] = useState(null),
    [uploadOpen, setUploadOpen] = useState(false),
    [sheet, setSheet] = useState({}),
    [validData, setValidData] = useState([])

  const { data, isLoading } = useQuery("players", () => fetchPlayers(), {
    select: (data) => data.players,
    refetchOnWindowFocus: false,
  });

  const bulkUpload = async (players) => {
    try {
      await addPlayer(players)
      alert("Players have been successfully added")
      window.location.reload()
    } catch (e) {
      if (e.data && e.data.message) {
        alert(e.data.message)
      }
    }
  }

  useEffect(() => {
    if (Object.entries(sheet).length) {
      console.log(sheet)
      if (sheet.invalidData.length) {
        alert("Some imported data are invalid and the upload will proceed with the rest")
      }
      bulkUpload(sheet.validData)
    }
  }, [sheet])

  const eventsColumns = [
    {
      field: "title",
      headerName: "Title",
      flex: 0.5,
    },
    {
      field: "start_date",
      headerName: "Starts On",
      flex: 0.7,
    },
    {
      field: "end_date",
      headerName: "Ends On",
      flex: 0.7,
    },
    {
      field: "price",
      headerName: "Paid",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <span>
            EGP{" "}
            {Number(params.row.price)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        );
      },
    },
    {
      field: "payment_source",
      headerName: "Payment Method",
      flex: 0.5,
      renderCell: (params) => {
        return <span>{params.row.voucher_id ? `Voucher` : `Card`}</span>
      }
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      flex: 0.5,
    },
  ];

  if (isLoading) return <>Loading...</>

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <ReactSpreadsheetImport
        isOpen={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onSubmit={setSheet}
        fields={fields}
      />

<BootstrapDialog
        onClose={() => {
          setEventsOpen(false);
        }}
        fullScreen={true}
        aria-labelledby="customized-dialog-title"
        open={eventsOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <i className="text-muted">{activeUser?.firstname}</i> events history
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setEventsOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="card card-warning">
          {/* <div className="card-header">
            <h3 className="card-title">
              Change {activeUser?.firstname} {data.lastname}'s Password
            </h3>
          </div> */}
          <div style={{ height: "100vh" }}>
            <DataGrid
              columns={eventsColumns}
              rows={activeUser?.events}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </div>
        </div>
      </BootstrapDialog>

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Players</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="d-flex flex-row align-items-center mb-3">
              <a href="/new-player" className="btn bg-gradient-primary mr-3">
                <i className="fa fa-plus mr-1" /> New player
              </a>

              <button
                className="btn bg-gradient-success mr-3"
                onClick={() => setUploadOpen(true)}
              >
                <i className="fa fa-file-excel mr-1" /> Import bulk players
              </button>

              <form
                method="get"
                action={`${process.env.PUBLIC_URL}/templates/players.xlsx`}
              >
                <button
                  className="btn bg-gradient-default"
                  type="submit"
                >
                  <i className="fa fa-download mr-1" /> Download template sheet
                </button>
              </form>
            </div>

            <div className="mb-5">
              <button
                className="btn bg-gradient-info"
                disabled={!selectedRows?.length}
                onClick={() => sendEmail({ users: selectedRows })}
              >
                <i className="fa fa-envelope mr-2" /> Send bulk email
              </button>
            </div>

            <Box sx={{ height: "80vh", width: "100%" }}>
              <DataGrid
                rows={data}
                loading={isLoading}
                slots={{
                  // toolbar: CustomToolbar,
                  toolbar: GridToolbar,
                }}
                columns={columns}
                disableRowSelectionOnClick
                checkboxSelection
                onRowSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 50,
                    },
                  },
                }}
                pageSizeOptions={[50]}
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Players;
