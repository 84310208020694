import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchNesletterSubscribers } from "../../services/";
import { Chip } from "@mui/material";
import Select from "react-select"

const Vouchers = () => {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      renderCell: (params) => {
        return <span>{params.row.firstname} {params.row.lastname}</span>
      }
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
    },
    {
        field: "is_player",
        headerName: "Membership",
        flex: 0.5,
        renderCell: (params) => {
            return <Chip size="small" color={`${params.row.is_player ? `success` : `warning`}`} label={`${params.row.is_player ? `Player` : `Guest`}`} />
        }
    },
    {
        field: "formatted_date_add",
        headerName: "Subscribed On",
        flex: 0.5,
    },
  ];

  const [filters, setFilters] = useState({})

  const { data, isLoading, refetch } = useQuery("players", () => fetchNesletterSubscribers(filters), {
    select: (data) => data.newsletter,
    refetchOnWindowFocus: false,
  });

  const clearFilter =(key) => {
    let filtersClone = {...filters}
    delete filtersClone[key]
    setFilters(filtersClone)
  }

  useEffect(() => {
    if (filters) { refetch() }
  }, [filters])

  if (isLoading) return <>Loading...</>

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Newsletter Subscribers</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            {/* <div className="d-flex flex-row align-items-center mb-5">
              <a href="/new-voucher" className="btn bg-gradient-primary mr-3">
                <i className="fa fa-plus mr-1" /> New voucher
              </a>
            </div> */}

            <div className="row mb-3">
                <div className="col-lg-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Filter by Email"
                        onChange={({ target }) => setFilters({ ...filters, email: target.value })}
                    />
                </div>

                <div className="col-lg-3">
                    <Select
                        options={[
                            { label: "Only members", value: 1 },
                            { label: "Only guests", value: 2 }
                        ]}
                        isClearable
                        onChange={(value, triggeredAction) => {
                            if (value && value.value) setFilters({ ...filters, is_player: value.value })

                            if (triggeredAction.action === "clear") {
                             clearFilter("is_player")   
                            }
                        }}
                    />
                </div>
            </div>

            <Box sx={{ height: "80vh", width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                slots={{
                  toolbar: GridToolbar,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 50,
                    },
                  },
                }}
                pageSizeOptions={[50]}
                disableRowSelectionOnClick
              />
            </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Vouchers;
