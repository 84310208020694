import react, { useState, useEffect, Component } from "react"
import { useLocation } from 'react-router-dom'

const Menu = () => {
    const location = useLocation()

    useEffect(() => {
        // navigate.listen((location) => {
            // console.log(location.pathname)
        // })
    }, [location])
    
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" className="brand-link">
            <img src={`${process.env.PUBLIC_URL}/images/new-logo-trs.png`} alt="Society of Play" />
            <span className="brand-text font-weight-light">&nbsp;</span>
        </a>
        <div className="sidebar">
            {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
                <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
                <a href="#" className="d-block">Alexander Pierce</a>
            </div>
            </div> */}
            
            <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {/* <li className="nav-item">
                    <a href="/" className={`nav-link ${location.pathname === "/" ? "active" : ""}`}>
                        <i className="nav-icon fas fa-tachometer-alt" />
                        <p>
                        Dashboard
                        </p>
                    </a>
                </li> */}
                
                {/* <li className="nav-header">Inventory</li> */}
                
                {/* <li className="nav-item">
                    <a
                        href="/departments"
                        className={`nav-link ${location.pathname.includes("/departments") ? "active" : ""}`}
                    >
                        <i className="nav-icon fas fa-tachometer-alt" />
                        <p>
                        Departments
                        </p>
                    </a>
                </li> */}

                <li className="nav-item">
                    <a href="/admins" className={`nav-link ${location.pathname.includes("admin") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-user-secret" />
                        <p>
                        Admin users
                        </p>
                    </a>
                </li>
                
                <li className="nav-item">
                    <a href="/players" className={`nav-link ${location.pathname.includes("player") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-user" />
                        <p>
                        Players
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/tiers" className={`nav-link ${location.pathname.includes("tier") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-certificate" />
                        <p>
                        Tiers
                        </p>
                    </a>
                </li>
                
                {/* <li className="nav-header">Administrative</li> */}
                
                <li className="nav-item">
                    <a href="/events" className={`nav-link ${location.pathname.includes("event") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-calendar" />
                        <p>
                        Events
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/vouchers" className={`nav-link ${location.pathname.includes("voucher") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-percentage" />
                        <p>
                        Vouchers
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/gallery" className={`nav-link ${location.pathname.includes("gallery") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-image" />
                        <p>
                        Gallery
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/messages" className={`nav-link ${location.pathname.includes("message") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-envelope" />
                        <p>
                        Messages
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/newsletter" className={`nav-link ${location.pathname.includes("newsletter") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-envelope-open-text" />
                        <p>
                        Newsletter
                        </p>
                    </a>
                </li>

                {/* <li className="nav-header">Reports</li> */}
                
                <li className="nav-item">
                    <a href="/experience" className={`nav-link ${location.pathname.includes("experience") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-handshake" />
                        <p>
                        Experience
                        </p>
                    </a>
                </li>
            </ul>
            </nav>
        </div>
        </aside>
    )
}

export default Menu