import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { addPlayer, fetchAreas } from "../../services/";
import Select from "react-select";

const AddPlayer = () => {
  const [ages, setAges] = useState([]);

  const { data: areas, isLoading } = useQuery("areas", fetchAreas, {
    select: (data) =>
      data.areas.map((a) => {
        return { label: a.name, value: a.id };
      }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let generatedAges = [];
    for (var i = 21; i <= 50; i++) {
      generatedAges.push({ label: i, value: i });
    }
    setAges(generatedAges);
  }, []);

  const saveChanges = async (values) => {
    try {
      await addPlayer([values]);
      alert("Player has been successfully added");
      window.location.href = "/players";
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New player</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/players">Players</a>
                  </li>
                  <li className="breadcrumb-item active">New player</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Player info</h3>
                  </div>

                  <Formik
                    initialValues={{
                    //   username: "",
                      firstname: "",
                      lastname: "",
                      email: "",
                      mobile: "",
                      area: "",
                      gender: "",
                      age: "",
                      dream_job: "",
                      password: "",
                      password_conf: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveChanges(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                    //   username: Yup.string().required(),
                      firstname: Yup.string().required(),
                      lastname: Yup.string().required(),
                      email: Yup.string().email().required(),
                      mobile: Yup.string().notRequired(),
                      area: Yup.string().required(),
                      gender: Yup.string().required(),
                      age: Yup.string().required(),
                      dream_job: Yup.string().required(),
                      password: Yup.string()
                        .min(
                          8,
                          "Password is too short - should be 8 chars minimum."
                        )
                        .required("No password provided"),
                      password_conf: Yup.string()
                        .required("You need to confirm the new password")
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Two passwords must match"
                        ),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Area</label>
                                  <Select
                                    name="area"
                                    options={areas}
                                    onChange={({ value }) =>
                                      setFieldValue("area", value)
                                    }
                                    onBlur={handleBlur}
                                    defaultValue={values.area}
                                  />
                                  {errors.area && touched.area && (
                                    <span className="text-danger text-sm">
                                      {errors.area}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-3">
                                  <label>Gender</label>
                                  <Select
                                    name="gender"
                                    options={[
                                      { label: "Male", value: "male" },
                                      { label: "Female", value: "female" },
                                    ]}
                                    onChange={({ value }) =>
                                      setFieldValue("gender", value)
                                    }
                                    onBlur={handleBlur}
                                    defaultValue={values.gender}
                                  />
                                  {errors.gender && touched.gender && (
                                    <span className="text-danger text-sm">
                                      {errors.gender}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-3">
                                  <label>Age</label>
                                  <Select
                                    options={ages}
                                    onChange={({ value }) =>
                                      setFieldValue("age", value)
                                    }
                                    name="age"
                                    defaultValue={values.age}
                                    onBlur={handleBlur}
                                  />
                                  {errors.age && touched.age && (
                                    <span className="text-danger text-sm">
                                      {errors.age}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label>Dream job</label>
                              <textarea
                                className="form-control"
                                placeholder="Dream job"
                                name="dream_job"
                                value={values.dreamjob}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.dream_job && touched.dream_job && (
                                <span className="text-danger text-sm">
                                  {errors.dream_job}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Firstname</label>

                                  <input
                                    // autoComplete="off"
                                    type="text"
                                    name="firstname"
                                    placeholder="Firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstname}
                                    className="form-control"
                                  />
                                  {errors.firstname && touched.firstname && (
                                    <span className="text-danger text-sm">
                                      {errors.firstname}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Lastname</label>

                                  <input
                                    type="text"
                                    name="lastname"
                                    placeholder="Lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastname}
                                    className="form-control"
                                  />
                                  {errors.lastname && touched.lastname && (
                                    <span className="text-danger text-sm">
                                      {errors.lastname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Email address</label>

                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    className="form-control"
                                  />
                                  {errors.email && touched.email && (
                                    <span className="text-danger text-sm">
                                      {errors.email}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Mobile phone</label>

                                  <input
                                    type="text"
                                    name="mobile"
                                    placeholder="Mobile phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.mobile || ""}
                                    className="form-control"
                                  />
                                  {errors.mobile && touched.mobile && (
                                    <span className="text-danger text-sm">
                                      {errors.mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Password</label>

                                  <input
                                    autoComplete="off"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    className="form-control"
                                  />
                                  {errors.password && touched.password && (
                                    <span className="text-danger text-sm">
                                      {errors.password}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Confirm Password</label>

                                  <input
                                    autoComplete="off"
                                    type="password"
                                    name="password_conf"
                                    placeholder="Confirm Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password_conf || ""}
                                    className="form-control"
                                  />
                                  {errors.password_conf &&
                                    touched.password_conf && (
                                      <span className="text-danger text-sm">
                                        {errors.password_conf}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn bg-gradient-primary"
                              disabled={isSubmitting}
                            >
                              Create Player
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlayer;
