import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchExperienceMessages, updateExperience } from "../../services/";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Chip, Divider } from "@mui/material";
import Select from "react-select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Experience = () => {
  const [modal, setModal] = useState(false),
  [activeMessage, setActiveMessage] = useState(null)

    const { data, isLoading, refetch } = useQuery("messages", fetchExperienceMessages, {
      select: (data) => data.messages,
      refetchOnWindowFocus: false,
    });

    const updateExperienceMessage = async (status, id) => {
        try {
            await updateExperience({ is_read: status ? 0 : 1 }, id)
            alert("Messages have been successfully updated")
            window.location.reload()
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

  const columns = [
    {
      field: "business_full_name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "business_email",
      headerName: "Email",
      flex: 0.7,
    },
    {
      field: "event_type",
      headerName: "Event Type",
      flex: 0.6,
    },
    {
      field: "is_fully_responsible",
      headerName: "Resposibility",
      flex: 0.4,
      renderCell: (params) => {
        return (<Chip color={params.row.is_fully_responsible ? `success` : `warning`} label={params.row.is_fully_responsible ? `FULLY` : `PARTIALLY`} size="small" />)
      }
    },
    {
      field: "formatted_date_add",
      headerName: "Sent On",
      flex: 0.6,
    },
    {
      field: "formatted_modified",
      headerName: "Last Updated",
      flex: 0.6,
    },
    {
      field: "Actions",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <button
              className="btn btn-sm bg-gradient-info mr-2"
              data-toggle="tooltip"
              data-placement="top"
              title="Details"
              onClick={() => {
                setModal(true);
                setActiveMessage(params.row);
              }}
            >
              <i className="fa fa-eye" />
            </button>

            <button
              className={`btn btn-sm bg-gradient-${params.row.is_read ? `danger` : `success`}`}
              data-toggle="tooltip"
              data-placement="top"
              title={`Mark as ${params.row.is_read ? `unread` : `read`}`}
              onClick={() => {
                updateExperienceMessage(params.row.is_read, params.row.id);
              }}
            >
              <i className={`fa fa-envelope${params.row.is_read ? `` : `-open`}`} />
            </button>
          </div>
        );
      },
    },
  ];

  if (isLoading) return <>Loading...</>

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <BootstrapDialog
        onClose={() => {
          setModal(false);
        }}
        fullScreen={true}
        aria-labelledby="customized-dialog-title"
        open={modal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Experience message details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="card card-warning">
          {/* <div className="card-header">
            <h3 className="card-title">
              Change {activeUser?.firstname} {data.lastname}'s Password
            </h3>
          </div> */}

          <div className="card-body">
          <h5>What is the type of the event? (ex: activation, opening, team building...)</h5>
          <p className="text-muted">{activeMessage?.event_type}</p>

          <Divider className="mb-3" />

          <h5>Event/Activity Objective</h5>
          <p className="text-muted">{activeMessage?.objective}</p>

          <Divider className="mb-3" />

          <h5>The Community’s Culture & Nature (Physical limitation & conservativeness)</h5>
          <p className="text-muted">{activeMessage?.community_culture}</p>

          <Divider className="mb-3" />

          <h5>Guests Profiles/Age group (Mgmt., Seniors & Juniors)</h5>
          <p className="text-muted">{activeMessage?.guest_profile}</p>

          <Divider className="mb-3" />

          <h5>The Company’s Core values, mission & vision & goals</h5>
          <p className="text-muted">{activeMessage?.core_values}</p>

          <Divider className="mb-3" />

          <h5>Any hidden messages, directions, theme for this year that we need to hammer on to be sent to the guests</h5>
          <p className="text-muted">{activeMessage?.hidden_messages}</p>

          <Divider className="mb-3" />

          <h5>Any specific learnings that you want to come out with? And what are your previous learnings that you’d rather avoid?</h5>
          <p className="text-muted">{activeMessage?.specific_learnings}</p>

          <Divider className="mb-3" />

          <h5>What specific objectives do you want your guests to be getting out of this activity achieving?</h5>
          <p className="text-muted">{activeMessage?.specific_objective}</p>

          <Divider className="mb-3" />

          <h5>Do you have an agenda for the event? If yes, please specify.</h5>
          <p className="text-muted">{activeMessage?.agenda}</p>

          </div>
        </div>
      </BootstrapDialog>

      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Experience Messages</h1>
            </div>
          </div>
          {/* </div> */}
        </div>

        <Box sx={{ height: "80vh", width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                slots={{
                  toolbar: GridToolbar,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 50,
                    },
                  },
                }}
                pageSizeOptions={[50]}
                disableRowSelectionOnClick
              />
            </Box>
      </div>
    </div>
  );
};

export default Experience;
