import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const login = async (data) => {
    return request({
        url: `${BaseUrl}v1/admin/login`,
        method: "POST",
        data
    })
}

export const fetchUser = async () => {
    return request({
        url: `${BaseUrl}v1/me`
    }, true)
}

export const fetchAdmins = async (id=null) => {
    let query = id ? `?id=${id}` : ``
    return request({
        url: `${BaseUrl}v1/admins${query}`
    }, true)
}

export const updateAdmin = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/admin/${id}`,
        method: "PUT",
        data
    }, true)
}

export const addAdmin = async (data) => {
    return request({
        url: `${BaseUrl}v1/create-user`,
        method: "POST",
        data
    }, true)
}

export const fetchPlayers = async (id=null) => {
    let query = id ? `?id=${id}` : ``
    return request({
        url: `${BaseUrl}v1/players${query}`
    }, true)
}

export const updatePlayer = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/player/${id}`,
        method: "PUT",
        data
    }, true)
}

export const addPlayer = async (data) => {
    return request({
        url: `${BaseUrl}v1/create-player`,
        method: "POST",
        data
    }, true)
}

export const fetchAreas = async () => {
    return request({
        url: `${BaseUrl}v1/areas`
    }, true)
}

export const fetchNesletterSubscribers = async (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    return request({
        url: `${BaseUrl}v1/newsletter${queryStr}`,
    }, true)
}

export const fetchTiers = async (id=null) => {
    return request({
        url: `${BaseUrl}v1/tiers${id ? `?id=${id}` : ``}`
    }, true)
}

export const addTier = async (data) => {
    return request({
        url: `${BaseUrl}v1/create-tier`,
        method: "POST",
        data
    }, true)
}

export const updateTier = async (data, tierId) => {
    return request({
        url: `${BaseUrl}v1/tier/${tierId}`,
        method: "PUT",
        data
    }, true)
}

export const fetchExperienceMessages = async () => {
    return request({
        url: `${BaseUrl}v1/experience`
    }, true)
}

export const updateExperience = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/update-experience/${id}`,
        method: "PUT",
        data
    }, true)
}

export const fetchContactMessages = async () => {
    return request({
        url: `${BaseUrl}v1/messages`
    }, true)
}

export const updateMessage = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/message/${id}`,
        method: "PUT",
        data
    }, true)
}

export const sendEmail = async (data) => {
    return request({
        url: `${BaseUrl}v1/send-mail`,
        method: "POST",
        data
    }, true)
}