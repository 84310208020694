import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { addVoucher } from "../../services/";
import Select from "react-select";

const AddVoucher = () => {
  const saveChanges = async (values) => {
    try {
      await addVoucher(values);
      alert("Voucher has been successfully added");
      window.location.href = "/vouchers";
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New voucher</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/vouchers">Vouchers</a>
                  </li>
                  <li className="breadcrumb-item active">New voucher</li>
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="row">
              <div className="col-lg-8">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Voucher info</h3>
                  </div>

                  <Formik
                    initialValues={{
                      name: "",
                      value: "",
                      is_percent: "",
                      n_usage: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveChanges(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required(),
                      value: Yup.number().required(),
                      is_percent: Yup.bool().required(),
                      n_usage: Yup.number().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Voucher Name</label>

                                  <input
                                    // autoComplete="off"
                                    type="text"
                                    name="name"
                                    placeholder="Voucher Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    className="form-control"
                                  />
                                  {errors.name && touched.name && (
                                    <span className="text-danger text-sm">
                                      {errors.name}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Value</label>

                                  <input
                                    type="number"
                                    name="value"
                                    placeholder="Voucher Value"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.value}
                                    className="form-control"
                                  />
                                  {errors.value && touched.value && (
                                    <span className="text-danger text-sm">
                                      {errors.value}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Voucher type</label>

                                  <Select
                                    options={[
                                        { label: "Percentage", value: "1" },
                                        { label: "Fixed Price", value: "0" }
                                    ]}
                                    name="is_percent"
                                    defaultValue={values.is_percent}
                                    onChange={(value) => {
                                        if (value && value.value) {
                                            setFieldValue("is_percent", value.value)
                                        }
                                    }}
                                  />
                                  {errors.is_percent && touched.is_percent && (
                                    <span className="text-danger text-sm">
                                      {errors.is_percent}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Usage Limit</label>

                                  <input
                                    type="number"
                                    name="n_usage"
                                    placeholder="Usage Limit"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.n_usage}
                                    className="form-control"
                                  />
                                  {errors.n_usage && touched.n_usage && (
                                    <span className="text-danger text-sm">
                                      {errors.n_usage}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn bg-gradient-primary"
                              disabled={isSubmitting}
                            >
                              Create Voucher
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddVoucher;
