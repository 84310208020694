import React, { useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { fetchAdmins, updateAdmin } from "../../services/";
import { useParams } from "react-router-dom";

const EditAdmin = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery("info", () => fetchAdmins(id), {
    select: (data) => data.admins[0],
    refetchOnWindowFocus: false
  });

  const saveChanges = async (values) => {
    try {
      await updateAdmin(values, id);
      alert("Admin user has been successfully updated");
      window.location.href = "/admins";
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Admin users</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/admins">Admin Users</a>
                  </li>
                  <li className="breadcrumb-item active">Edit</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="card card-warning">
                  <div className="card-header">
                    <h3 className="card-title">
                      Edit {data.firstname} {data.lastname}'s' Info
                    </h3>
                  </div>

                  <Formik
                    initialValues={{
                      username: data.username,
                      firstname: data.firstname,
                      lastname: data.lastname,
                      email: data.email,
                      mobile: data.mobile,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveChanges(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      username: Yup.string().required(),
                      firstname: Yup.string().required(),
                      lastname: Yup.string().required(),
                      email: Yup.string().email().required(),
                      mobile: Yup.string().notRequired(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <label>Username</label>
                              <input
                                autoComplete="off"
                                type="text"
                                name="username"
                                placeholder="Username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                className="form-control"
                              />
                              {errors.username && touched.username && (
                                <span className="text-danger text-sm">
                                  {errors.username}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Firstname</label>

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="firstname"
                                    placeholder="Firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstname}
                                    className="form-control"
                                  />
                                  {errors.firstname && touched.firstname && (
                                    <span className="text-danger text-sm">
                                      {errors.firstname}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Lastname</label>

                                  <input
                                    type="text"
                                    name="lastname"
                                    placeholder="Lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastname}
                                    className="form-control"
                                  />
                                  {errors.lastname && touched.lastname && (
                                    <span className="text-danger text-sm">
                                      {errors.lastname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Email address</label>

                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    className="form-control"
                                  />
                                  {errors.email && touched.email && (
                                    <span className="text-danger text-sm">
                                      {errors.email}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Mobile phone</label>

                                  <input
                                    type="text"
                                    name="mobile"
                                    placeholder="Mobile phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.mobile || ""}
                                    className="form-control"
                                  />
                                  {errors.mobile && touched.mobile && (
                                    <span className="text-danger text-sm">
                                      {errors.mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Password</label>

                                  <input
                                    autoComplete="off"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    className="form-control"
                                  />
                                  {errors.password && touched.password && (
                                    <span className="text-danger text-sm">
                                      {errors.password}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Confirm Password</label>

                                  <input
                                    autoComplete="off"
                                    type="password"
                                    name="password_conf"
                                    placeholder="Confirm Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password_conf || ""}
                                    className="form-control"
                                  />
                                  {errors.password_conf &&
                                    touched.password_conf && (
                                      <span className="text-danger text-sm">
                                        {errors.password_conf}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn bg-gradient-warning"
                              disabled={isSubmitting}
                            >
                              Save changes
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;
