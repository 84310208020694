import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchAdmins, updateAdmin } from "../../services/";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Admins = () => {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.3,
      renderCell: (params) => {
        return <span>{`${params.row.firstname} ${params.row.lastname}`}</span>;
      },
    },
    {
      field: "username",
      headerName: "Username",
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "E-mail address",
      flex: 0.3,
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      flex: 0.3,
    },
    {
      field: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <a
              className="btn btn-sm bg-gradient-dark mr-2"
              href={`/edit-admin/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>
  
            <button
              className="btn btn-sm bg-gradient-info"
              data-toggle="tooltip"
              data-placement="top"
              title="Change password"
              onClick={() => setActiveUser(params.row)}
            >
              <i className="fa fa-lock" />
            </button>
          </div>
        );
      },
    },
  ];

  const [activeUser, setActiveUser] = useState(null),
    [open, setopen] = useState(false);

  const { data, isLoading } = useQuery("adminUsers", () => fetchAdmins(), {
    select: (data) => data.admins,
    refetchOnWindowFocus: false
  });

  const savePassword = async (values) => {
    try {
      await updateAdmin(values, activeUser.id);
      alert("Admin password has been successfully changed")
      setopen(false)
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  useEffect(() => {
    if (activeUser) {
      setopen(true)
    }
  }, [activeUser])

  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <BootstrapDialog
        onClose={() => {
          setopen(false);
        }}
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Change password for {activeUser?.username}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setopen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="card card-warning">
          {/* <div className="card-header">
            <h3 className="card-title">
              Change {activeUser?.firstname} {data.lastname}'s Password
            </h3>
          </div> */}

          <Formik
            initialValues={{
              password: "",
              password_conf: ""
            }}
            onSubmit={(values, { setSubmitting }) => {
              savePassword(values);
              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8, "Password is too short - should be 8 chars minimum.")
                .required("No password provided"),
              password_conf: Yup.string().required("You need to confirm the new password").oneOf(
                [Yup.ref("password"), null],
                "Two passwords must match"
              ),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        placeholder="New password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        autoComplete="off"
                        value={values.password}
                      />
                      {errors.password && touched.password && (
                        <span className="text-danger text-sm">
                          {errors.password}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        name="password_conf"
                        placeholder="Confirm password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        autoComplete="off"
                        value={values.password_conf}
                      />
                      {errors.password_conf && touched.password_conf && (
                        <span className="text-danger text-sm">
                          {errors.password_conf}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="card-footer">
                    <button
                      type="submit"
                      className="btn bg-gradient-info"
                      disabled={isSubmitting}
                    >
                      Update password
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </BootstrapDialog>

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Admin users</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row mb-4">
              <div className="col-lg-3">
                <a href="/new-admin" className="btn bg-gradient-primary">
                  <i className="fa fa-plus" /> New admin user
                </a>
              </div>
            </div>

            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;
