import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { Formik, FieldArray, Form, Field } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
    fetchEventQuestions,
    saveQuestions
} from "../../services";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Questions = () => {
  const [modal, setModal] = useState(false);
  const { event_id } = useParams();

  const { data, isLoading, refetch } = useQuery(
    "questions",
    () => fetchEventQuestions(event_id),
    {
      select: (data) => data.questions,
      refetchOnWindowFocus: false,
    }
  );

  const updateQuestions = async (data) => {
    try {
        data.questions.forEach((d) => {
            d.is_select = Number(d.is_select)
            d.is_required = Number(d.is_required)
            d.event_id = event_id
        })
        
        await saveQuestions(data, event_id)
        setModal(false)
        refetch()
    } catch (e) {
        if (e.data && e.data.message) alert(e.data.message)
    }
  }

  if (isLoading) return <span>Loading...</span>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <BootstrapDialog
        onClose={() => {
          setModal(false);
        }}
        fullScreen={true}
        aria-labelledby="customized-dialog-title"
        open={modal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add new questions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <div style={{ height: "100vh" }}>
                  <Formik
                    initialValues={{
                      /*questions: [
                        {
                          content: "",
                          is_select: "",
                          options: "",
                          is_required: "1",
                        },
                      ],*/
                      questions: data
                    }}
                    // validationSchema={Yup.object().shape({
                    //     questions: Yup.array().of(
                    //         Yup.object().shape({
                    //             content: Yup.string().required(),
                    //             options: Yup.string().when("is_select", {
                    //                 is: "1",
                    //                 then: Yup.string().required("Please add at least two options"),
                    //                 otherwise: Yup.string().notRequired()
                    //             }),
                    //             is_required: Yup.boolean().oneOf([true], "This field is required")
                    //         })
                    //     )
                    // })}
                    onSubmit={(values, { setSubmitting }) => {
                        updateQuestions(values)
                        setSubmitting(false)
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <Form>
                          <FieldArray
                            name="questions"
                            render={(arrayHelpers) => (
                              <div>
                                {values.questions && values.questions.length ? (
                                  values.questions.map((question, index) => (
                                    <div key={index}>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-lg-10">
                                            <label className="d-block">
                                              Question {index + 1}
                                            </label>

                                            <Field
                                              name={`questions.${index}.content`}
                                              placeholder="Question content"
                                              className="form-control"
                                              value={
                                                values.questions[index].content
                                              }
                                              onChange={handleChange}
                                            />
                                          </div>

                                          <div className="col-lg-2">
                                            <label className="d-block">
                                              &nbsp;
                                            </label>
                                            
                                            {index == 0 ?
                                            <button
                                              type="button"
                                              className="btn btn-default"
                                              onClick={() =>
                                                arrayHelpers.push("")
                                              }
                                            >
                                              <i className="fa fa-plus" />
                                            </button>

                                            :

                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <i className="fa fa-minus" />
                                            </button>
                                            }
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-lg-5">
                                            <label>Question type</label>

                                            <Select
                                              name={`questions.${index}.is_select`}
                                              isClearable
                                              defaultValue={
                                                Number(values.questions[index]
                                                  .is_select) === 1 ? {
                                                    label: "Multiple options",
                                                    value: "1",
                                                  } : {
                                                    label: "Free text",
                                                    value: "0",
                                                  }
                                              }
                                              options={[
                                                {
                                                  label: "Multiple options",
                                                  value: "1",
                                                },
                                                {
                                                  label: "Free text",
                                                  value: "0",
                                                },
                                              ]}
                                              onChange={(
                                                value,
                                                triggeredAction
                                              ) => {
                                                if (value && value.value) {
                                                  setFieldValue(
                                                    `questions.${index}.is_select`,
                                                    value.value
                                                  );
                                                }

                                                if (
                                                  triggeredAction.action ==
                                                  "clear"
                                                ) {
                                                  setFieldValue(
                                                    `questions.${index}.is_select`,
                                                    ""
                                                  )
                                                  setFieldValue(`questions.${index}.options`, "")
                                                }
                                              }}
                                            />
                                          </div>

                                          <div className="col-lg-5">
                                            <label>Options</label>

                                            <Field
                                              name={`questions.${index}.options`}
                                              placeholder="Enter options comma separated"
                                              disabled={Number(values.questions[index].is_select) === 0}
                                              value={
                                                values.questions[index].options
                                              }
                                              className="form-control"
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        <label>Is Required ?</label>
                                        <Checkbox
                                          name={`questions.${index}.is_required`}
                                          onChange={handleChange}
                                          value={values.questions[index].is_required}
                                        //   checked={Number(values.questions[index].is_required) === 1 ? true : false}
                                        />
                                      </div>

                                      {index < values.questions.length - 1 && <Divider className="mb-3" />}
                                    </div>
                                  ))
                                ) : (
                                  <button
                                    className="btn btn-default"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    <i className="fa fa-plus" />
                                  </button>
                                )}

                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="btn bg-gradient-primary"
                                >
                                  Save questions
                                </button>
                              </div>
                            )}
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
      </BootstrapDialog>

      <div className="content-wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">RSVP Questions</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/events">Events</a>
                </li>
                <li className="breadcrumb-item active">RSVP Questions</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="row mb-2">
            <div className="col-lg-12">
              <button
                className="btn bg-gradient-primary"
                onClick={() => setModal(true)}
              >
                <i className="fa fa-plus mr-2" /> Add questions
              </button>
            </div>
          </div>
          {data.length ? (
            data.map((question) => (
              <Accordion expanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{question.content}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>Type:</b> {question.is_select ? `Multiple Options` : `Free text`}
                  </Typography>

                  <Typography>
                    {question.is_select ? (<><b>Options:</b> {question.options}</>) : ``}
                  </Typography>

                  <Typography>
                    <b>Required:</b> {question.is_required ? `Required` : `Optional`}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <small className="text-muted text-center">
              No questions added yet for this event
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default Questions;
