import React, { useEffect } from "react"
import Header from "../components/Header"
import Menu from "../components/Menu"

const Dashboard = () => {
    useEffect(() => {
        console.log("Hello Dashboard")
    }, [])
    
    return (
        <div className="wrapper">
            <Header />
        <Menu />
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                            <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h5 className="m-0">Dashboard</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Dashboard